export async function tokenFetch<T>(
  url: string,
  serveCacheIfAvailable = false,
  ttl = 60 * 60 * 24, // 1 day
): Promise<T> {
  const cache = useIndexedDBCacheStore();

  if (serveCacheIfAvailable) {
    const data = await cache.get(url);
    if (data !== null) {
      return data as T;
    }
  }

  const api = useApiStore();

  const urlObj = new URL(url);
  const uri = urlObj.pathname;

  const token = await api.post("/GetCCLITop100Token", { url: uri });
  if (!token) {
    throw new Error("Error generating token");
  }

  const fullurl = `${url}?verify=${token}`;
  const request = await fetch(fullurl);
  if (!request.ok) {
    console.error("Error fetching data: " + fullurl, request.status);
    throw new Error("Error fetching data: " + fullurl);
  }
  const data = (await request.json()) as T;

  await cache.set(url, data, ttl);
  return data;
}
