import { ChartRowItem } from "@/models/frontend-only/ChartRowItem";
import {
  ChartBadge,
  FESearchResult,
} from "@/models/frontend-only/FESearchResult";
import { ChartCountryInfo } from "@/models/frontend-only/ChartCountryInfo";
import { defineStore } from "pinia";

export const useChartStore = defineStore("chart", () => {
  const beta = useBetaStore();
  const profile = useProfileStore();

  const availableCountries = ref<ChartCountryInfo[]>([]);
  const availableCharts = computed(() => {
    const allCharts = availableCountries.value.map((c) => ({
      chartCountryCode: c.ChartCountryCode,
      serviceCenter: c.ServiceCenter,
      chartType: c.ChartType,
      limitRanksTo: c.LimitRanksTo,
      visible: c.Visible,
    }));
    const charts: typeof allCharts = [];
    allCharts.forEach((c) => {
      if (!charts.find((cc) => cc.chartCountryCode === c.chartCountryCode)) {
        charts.push(c);
      }
    });
    return sortBy(charts, (c) => c.serviceCenter);
  });

  const defaultCountry = computed(() => {
    const fallbackCountry: ChartCountryInfo = {
      Country: "US",
      ServiceCenter: "United States",
      ChartCountryCode: "US",
      TerritoryID: 1,
      Culture: "en-US",
      ChartType: "ccli-top-100",
      LimitRanksTo: 100,
      Visible: true,
    };

    const country = availableCountries.value.find(
      (c) => c.Country === profile.profile?.countryCode,
    );

    return country || fallbackCountry;
  });

  function isCCLIChartAvailable(chartCountryCode: string) {
    if (
      availableCountries.value.findIndex(
        (c) => c.ChartCountryCode === chartCountryCode,
      ) === -1
    ) {
      return false;
    }
    return true;
  }

  const geoChartWeeks = ref<{ [chartCountryCode: string]: string[] }>({});

  async function getChartWeeks(chartCountryCode: string) {
    if (!isCCLIChartAvailable(chartCountryCode)) {
      console.error("Invalid country code", chartCountryCode);
      throw new Error("Invalid country code");
    }

    if (geoChartWeeks.value[chartCountryCode]) {
      return Array.from(geoChartWeeks.value[chartCountryCode]);
    }

    const data = await tokenFetch<{ WeekOf: string }[]>(
      `${AppConstants.CHART_BASE_URL}/${chartCountryCode}/chartWeeks.json`,
      true,
    );

    const dataWeeks = data.map((d) => d.WeekOf);

    geoChartWeeks.value[chartCountryCode] = dataWeeks;

    return Array.from(geoChartWeeks.value[chartCountryCode]);
  }

  async function getMostRecentChartWeek(chartCountryCode: string) {
    const chartWeeks = await getChartWeeks(chartCountryCode);
    const dates = sortBy(chartWeeks, (c) => c);
    const date = dates.slice(-1)[0];
    const results = await getChartData(chartCountryCode, date);
    return { date, results };
  }

  async function getAvailableCountries() {
    const data = await tokenFetch<ChartCountryInfo[]>(
      `${AppConstants.CHART_BASE_URL}/countryChartType.json`,
      true,
    );

    availableCountries.value = data.filter((c) => c.Visible);
  }

  const cachedChartData = ref<{
    [countryCode: string]: { [weekOf: string]: FESearchResult[] };
  }>({});

  async function getChartData(countryCode: string, weekOf: string) {
    if (
      cachedChartData.value[countryCode] &&
      cachedChartData.value[countryCode][weekOf]
    ) {
      return Array.from(cachedChartData.value[countryCode][weekOf]);
    }

    if (!isCCLIChartAvailable(countryCode)) {
      console.error("Invalid country code", countryCode);
      throw new Error("Invalid country code");
    }

    const chartWeeks = await getChartWeeks(countryCode);

    if (chartWeeks.indexOf(weekOf) === -1) {
      throw new Error("Invalid week of date");
    }

    const results = await tokenFetch<ChartRowItem[]>(
      `${AppConstants.CHART_BASE_URL}/${countryCode}/${weekOf}.json`,
      true,
    );

    if (!results) {
      console.error("no results found for", countryCode, weekOf);
      throw new Error("No results found for this week");
    }
    const transformedResults: FESearchResult[] = results.map((r) => {
      const badges: ChartBadge[] = [];
      if (r.IsPublicDomain) {
        badges.push("public-domain");
      }
      if (r.IsTrending) {
        badges.push("trending");
      }
      if (r.IsReEntry) {
        badges.push("re-entry");
      }
      if (r.IsNewEntry) {
        badges.push("new-entry");
      }
      if (r.IsEvergreen) {
        badges.push("evergreen");
      }

      return {
        title: r.Title,
        authors: r.Authors.split("^"),
        slug: slugify(r.SongTitle),
        rank: r.ChartPosition,
        rankMovement: r.ChartMovement,
        weeksOnChart: r.WeeksInChart,
        songNumber: r.SongNumber.toString(),
        soundSample: {
          audioUrl: r.SoundSampleURL,
          imageUrl: r.AlbumArtURL,
        },
        productExists: {
          chords: r.HasChordSheet,
          lead: r.HasLeadSheet,
          lyrics: r.HasLyrics,
          multitracks: false,
          vocal: r.HasHymnSheet,
        },
        badges,
      };
    });
    if (!cachedChartData.value[countryCode]) {
      cachedChartData.value[countryCode] = {};
    }
    cachedChartData.value[countryCode][weekOf] = transformedResults;

    return Array.from(cachedChartData.value[countryCode][weekOf]);
  }

  async function init() {
    await Promise.all([getAvailableCountries()]);
  }

  if (beta.features.ccliTop100New) {
    init();
  }

  return {
    defaultCountry,
    init,
    getChartData,
    availableCountries,
    getChartWeeks,
    getMostRecentChartWeek,
    availableCharts,
  };
});
