import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";

const debug = useDebug();

function handleErr(e: Error) {
  const profile = useProfileStore();
  profile.getPing();
  throw e;
}

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    children: [
      {
        path: "",
        component: () => import("@/pages/Home.vue").catch(handleErr),
        name: "Home",
      },
      ...(import.meta.env.VITE_ENABLE_CCLI_TOP_100_NEW !== "0"
        ? [
            {
              path: "ccli-top-100",

              children: [
                {
                  path: "",
                  component: () =>
                    import("@/pages/Top100/Base.vue").catch(handleErr),
                },
                {
                  name: "ccli-top-100",
                  path: ":region",
                  component: () =>
                    import("@/pages/Top100/Results.vue").catch(handleErr),
                },
              ],
            },
            {
              path: "ccli-top-songs",

              children: [
                {
                  path: "",
                  component: () =>
                    import("@/pages/Top100/Base.vue").catch(handleErr),
                },
                {
                  name: "ccli-top-songs",
                  path: ":region",
                  component: () =>
                    import("@/pages/Top100/Results.vue").catch(handleErr),
                },
              ],
            },
          ]
        : []),
      ...(import.meta.env.VITE_ENABLE_LYRIC_VIDEO_TEST === "1"
        ? [
            {
              path: "lyric-videos",

              children: [
                {
                  path: "",
                  component: () =>
                    import("@/pages/LyricVideos/AllLyricVideos.vue").catch(
                      handleErr,
                    ),
                },
                {
                  path: ":videoid",

                  children: [
                    {
                      path: "",
                      component: () =>
                        import("@/pages/LyricVideos/LyricVideo.vue").catch(
                          handleErr,
                        ),
                    },
                    {
                      path: ":videoitemid",
                      component: () =>
                        import(
                          "@/pages/LyricVideos/LyricVideoVersion.vue"
                        ).catch(handleErr),
                    },
                  ],
                },
              ],
            },
          ]
        : []),
      {
        path: "search",
        children: [
          {
            path: "results",
            name: "search-results-page",
            component: () =>
              import("@/pages/SearchResultsPage.vue").catch(handleErr),
          },
          {
            path: "topsongslists",
            component: () => import("@/pages/TopSongs.vue").catch(handleErr),
          },
          {
            path: "liturgycalendar",
            name: "liturgycalendar",
            component: () =>
              import("@/pages/LiturgyCalendar.vue").catch(handleErr),
          },
          {
            path: "responsorialpsalms",
            component: () =>
              import("@/pages/ResponsorialPsalms.vue").catch(handleErr),
          },
          {
            path: "LiturgyDay",
            children: [
              {
                path: ":id",
                component: () =>
                  import("@/pages/LiturgyDate.vue").catch(handleErr),
              },
              {
                path: ":id/:slug",
                component: () =>
                  import("@/pages/LiturgyDate.vue").catch(handleErr),
              },
            ],
          },
          {
            path: "language",
            component: () =>
              import("@/pages/SongsByLanguage.vue").catch(handleErr),
          },
          {
            path: "themes",
            component: () => import("@/pages/Themes.vue").catch(handleErr),
          },
        ],
      },
      {
        path: "songs/:songNumber",
        component: () => import("@/pages/SongTemplate.vue").catch(handleErr),
        children: [
          {
            path: "",
            component: () => import("@/pages/General.vue").catch(handleErr),
          },
          {
            path: "viewlyrics",
            component: () => import("@/pages/Lyrics.vue").catch(handleErr),
            meta: {
              enablePrintView: true,
            },
          },
          {
            path: "viewchordsheet",
            component: () => import("@/pages/ChordSheet.vue").catch(handleErr),
          },
          {
            path: "viewleadsheet",
            component: () => import("@/pages/LeadSheet.vue").catch(handleErr),
          },
          {
            path: "viewvocalsheet",
            component: () => import("@/pages/VocalSheet.vue").catch(handleErr),
          },
        ],
      },
      {
        path: "songs/:songNumber/:slug",
        component: () => import("@/pages/SongTemplate.vue").catch(handleErr),
        children: [
          {
            path: "",
            component: () => import("@/pages/General.vue").catch(handleErr),
          },
          {
            path: "viewlyrics",
            component: () => import("@/pages/Lyrics.vue").catch(handleErr),
            meta: {
              enablePrintView: true,
            },
          },
          {
            path: "viewchordsheet",
            component: () => import("@/pages/ChordSheet.vue").catch(handleErr),
          },
          {
            path: "viewleadsheet",
            component: () => import("@/pages/LeadSheet.vue").catch(handleErr),
          },
          {
            path: "viewvocalsheet",
            component: () => import("@/pages/VocalSheet.vue").catch(handleErr),
          },
        ],
      },
    ],
  },
  {
    path: "/cachehelp",
    component: () => import("@/pages/Help.vue").catch(handleErr),
  },
  {
    path: "/favorites",
    component: () =>
      import("@/pages/Favorites/FavoritesTemplate.vue").catch(handleErr),
    children: [
      {
        path: "",
        redirect: "/favorites/personal",
      },
      {
        path: "userfavorites",
        redirect: "/favorites/personal",
      },
      {
        path: "personal",
        component: () =>
          import("@/pages/Favorites/Personal.vue").catch(handleErr),
      },
      {
        path: "shared",
        children: [
          {
            path: "",
            component: () =>
              import("@/pages/Favorites/Shared.vue").catch(handleErr),
          },
          {
            path: ":id",
            name: "favorites-list",
            component: () =>
              import("@/pages/Favorites/Folder.vue").catch(handleErr),
          },
        ],
      },
    ],
  },
  {
    path: "/account/activity",
    component: () =>
      import("@/pages/Activity/ActivityTemplate.vue").catch(handleErr),
    children: [
      {
        path: "",
        redirect: "/account/activity/unique-songs-used",
      },
      {
        path: "unique-songs-used",
        component: () =>
          import("@/pages/Activity/UniqueSongs.vue").catch(handleErr),
      },
      {
        path: "activity-details",
        component: () =>
          import("@/pages/Activity/ActivityDetail.vue").catch(handleErr),
      },
    ],
  },

  {
    path: "/callback/spotify",
    component: () =>
      import("@/pages/callback/SpotifyCallback.vue").catch(handleErr),
  },

  {
    path: "/about",
    children: [
      {
        path: "pricing",
        component: () => import("@/pages/Pricing.vue").catch(handleErr),
      },
      {
        path: "termsofuse",
        component: () => import("@/pages/TermsOfUse.vue").catch(handleErr),
      },
      {
        path: "contact",
        component: () => import("@/pages/Redirect.vue").catch(handleErr),
        meta: {
          redirect: "https://ccli.com/contact-ccli",
        },
      },
      {
        path: "",
        component: () => import("@/pages/Redirect.vue").catch(handleErr),
        meta: {
          redirect: "https://ccli.com/songselect",
        },
      },
      {
        path: ":catchAll(.*)",
        component: () => import("@/pages/Redirect.vue").catch(handleErr),
        meta: {
          redirect: "https://ccli.com/songselect",
        },
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    component: () => import("@/pages/NotFound.vue").catch(handleErr),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

let afterFirstRoute = false;
let LCPEvent = false;
emitter.on("LCPEvent", onLCPEvent);

function onLCPEvent() {
  emitter.off("LCPEvent", onLCPEvent);
  LCPEvent = true;
}

async function launchScripts() {
  if (!afterFirstRoute) {
    afterFirstRoute = true;
    const app = useAppStore();
    app.checkBrowserSupport();

    const route = useRoute();

    debug.log("wait", route?.name);
    if (route?.name === "Home") {
      await waitUntilTrue(() => onLCPEvent);
      await asyncWait(2000);
    }
    debug.log("wait finished");
    emitter.emit("readyToRunNonBlockingScripts");
  }
}

router.afterEach(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    launchScripts();
    if (
      !(
        from.params.songNumber &&
        to.params.songNumber &&
        from.params.songNumber === to.params.songNumber
      ) &&
      !(from.name == "liturgycalendar" && to.name === "liturgycalendar") &&
      !router.options.history.state.historyNavigation &&
      !(
        (from.name === "ccli-top-100" || from.name === "ccli-top-songs") &&
        (to.name === "ccli-top-100" || to.name === "ccli-top-songs")
      )
    ) {
      window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }
    const colorScheme = useDarkModeStore().isDarkModeEnabled ? "dark" : "light";

    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      preferred_color_scheme: colorScheme,
      is_beta_user: useBetaStore().enabled,
      userId: useProfileStore().profile?.userId || "",
    });
    (window as any).dataLayer.push({
      event: "vue_page",
    });
    if (to.fullPath.includes("/lyric-videos")) {
      (window as any).dataLayer.push({
        lyric_video_interaction: "yes",
      });
    }
  },
);

let nextPath = "";

router.onError((e: Error) => {
  launchScripts();
  const app = useAppStore();
  const profile = useProfileStore();
  if (!app.productionMode) {
    throw e;
  }

  debug.log("router error", e);
  profile.getPing();

  if (e.message.includes("Failed to fetch dynamically imported module:")) {
    if (nextPath) {
      window.location.replace(window.location.origin + nextPath);
    } else {
      document.location.reload();
    }
    return;
  }
});

router.beforeEach((to) => {
  nextPath = to.fullPath;

  const mobile = useMobileStore();
  mobile.toggleMobileNav(false);

  if (to.meta.enablePrintView) {
    document.body.classList.add("print-mode");
  } else {
    document.body.classList.remove("print-mode");
  }
});

router.afterEach(() => {
  const app = useAppStore();
  app.showFooter = true;
  router.options.history.state.historyNavigation = false;
});

window.addEventListener("popstate", () => {
  router.options.history.state.historyNavigation = true;
});

export default router;
